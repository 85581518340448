import { getCookie } from './getCookie.js';
import number_format from './number-format.js';
import $ from 'jquery';

export const showProductButton = () => {
    $('#product-cart-form .skeleton-add-to-cart-button').fadeIn('fast');

    $('#product-cart-form .open-modal-book-appointment').attr(
        'style',
        'display: none !important'
    );
    $('#product-cart-form .open-modal-select-store').attr(
        'style',
        'display: none !important'
    );
    $('#product-cart-form .single_add_to_cart_button').attr(
        'style',
        'display: none !important'
    );

    setTimeout(() => {
        $('#product-cart-form .skeleton-add-to-cart-button').fadeOut(
            'fast',
            () => {
                if (!getCookie('storeID')) {
                    $('#product-cart-form .open-modal-select-store').fadeIn();
                    return;
                }
                if (!getCookie('bookingDate')) {
                    $(
                        '#product-cart-form .open-modal-book-appointment'
                    ).fadeIn();
                    return;
                }

                $('#product-cart-form .single_add_to_cart_button').fadeIn();
                return;
            }
        );
    }, 1000);
};

export const updateTotalPrice = () => {
    const productPriceDisplay = $(
        '#single-product-total-to-pay-placement-display'
    );
    const productPlainPrice = $('#product-plain-price');
    const productPlainRRP = $('#product-plain-rrp');
    const productLogoAP = $('.payment-afterpay');
    const productLogoKlarna = $('#payment-klarna');
    const quantity = parseInt($('#product-cart-form .qty').val());
    const productQuantityDisplay = $('#single-product-quantity-display');
    productQuantityDisplay.html(quantity);

    if (!productPriceDisplay.length || !productPlainPrice.length) {
        return;
    }

    const priceElement = $('#single-product-total-price');
    const price = parseFloat(productPlainPrice.val());
    const currentTotalPrice = parseFloat(priceElement.html().replace(',', ''));

    // const totalPrice = price * quantity;
    const updatedPrice = price * quantity;

    // $('#single-product-total-price').html('$' + number_format(totalPrice, 2, '.', ','));

    const animationDuration = 500; // Duration of the animation in milliseconds
    const animationInterval = 50; // Interval between each increment in milliseconds

    if (updatedPrice > currentTotalPrice) {
        const increment =
            (updatedPrice - currentTotalPrice) /
            (animationDuration / animationInterval);
        let currentPrice = currentTotalPrice;

        const animation = setInterval(() => {
            currentPrice += increment;
            priceElement.html(
                number_format(Math.round(currentPrice), 2, '.', ',')
            );

            if (currentPrice >= updatedPrice) {
                clearInterval(animation);
                priceElement.html(number_format(updatedPrice, 2, '.', ','));
            }
        }, animationInterval);
    } else {
        const decrement =
            (currentTotalPrice - updatedPrice) /
            (animationDuration / animationInterval);
        let currentPrice = currentTotalPrice;

        const animation = setInterval(() => {
            currentPrice -= decrement;
            priceElement.html(
                number_format(Math.round(currentPrice), 2, '.', ',')
            );

            if (currentPrice <= updatedPrice) {
                clearInterval(animation);
                priceElement.html(number_format(updatedPrice, 2, '.', ','));
            }
        }, animationInterval);
    }

    productPriceDisplay.html(
        `$${number_format(updatedPrice / 4, 2, '.', ',')}`
    );

    if (productPlainRRP.length) {
        const rrp = parseFloat(productPlainRRP.val());
        const updatedRRP = (rrp - price) * quantity;
        $('#single-product-total-rrp').html(
            `total savings on ${quantity} tyres: <strong>$${number_format(
                updatedRRP,
                2,
                '.',
                ','
            )}</strong>`
        );
    }

    if (price * quantity > 3000) {
        productLogoAP.fadeOut();
    } else {
        productLogoAP.fadeIn();
    }

    if (price * quantity > 8000) {
        productLogoKlarna.fadeOut();
    } else {
        productLogoKlarna.fadeIn();
    }
};

const updateEnquireForm = () => {
    const $form = $('#single-product-enquire-form');

    // check if form exit
    if (!$form.length) {
        return;
    }

    const storeID = $('#product-store_id').val();
    var storeName = '';

    if (storeID) {
        storeName = $('#product-store_name').val();

        $('.forminator-row input[type="hidden"][value="[store_id]"]').val(
            storeID
        );
        $('.forminator-row input[type="hidden"][value="[store_name]"]').val(
            storeName
        );
    }
    // GRAVITY FORMS
    const $subject = $('#input_1_4');
    const $message = $('#input_1_5');

    if ($subject) {
        $subject.val(`Product Inquiry Submitted via the Website.`);
    }

    if ($message) {
        var title = $('.product_title.entry-title  .title').html();

        // remove tags from title
        title = title.replace(/(<([^>]+)>)/gi, '');

        $message.val(
            `Request for: ${title}\n\nProduct URL: ${window.location.href}`
        );

        if (storeName) {
            $message.val($message.val() + `\n\nPrefered Store: ${storeName}`);
        }
    }
};

$(() => {
    if (!$('#dynamic-product-cart-form').length) {
        return;
    }

    updateEnquireForm();
    showProductButton();
    updateTotalPrice();
});
