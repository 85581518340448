import $ from 'jquery';

$(() => {
  $('.modal-popup-close').on('click', (e) => {
    const target = $(e.target);

    let findParent = target;
    while (findParent) {
      if (findParent.parent().hasClass('modal-popup')) {
        findParent.parent().fadeOut('fast');
        findParent = false;
      } else {
        findParent = findParent.parent();
      }
    }
  });

  $('.modal-popup').on('click', (e) => {
    const target = $(e.target);

    if (target.hasClass('modal-popup')) {
      target.fadeOut('fast');
    }
  });
});
