import $ from 'jquery';

$(() => {
  // const products = $('.product-loop-item .featured-image');

  // if (products.length) {
  //     const nodes = [].slice.call(products, 0);
  //     const directions = { 0: 'top', 1: 'right', 2: 'bottom', 3: 'left' };
  //     const classNames = ['in', 'out'].map((p) => Object.values(directions).map((d) => `${p}-${d}`)).reduce((a, b) => a.concat(b));

  //     const getDirectionKey = (ev, node) => {
  //         const { width, height, top, left } = node.getBoundingClientRect();
  //         const l = ev.pageX - (left + window.pageXOffset);
  //         const t = ev.pageY - (top + window.pageYOffset);
  //         const x = (l - (width / 2) * (width > height ? (height / width) : 1));
  //         const y = (t - (height / 2) * (height > width ? (width / height) : 1));
  //         return Math.round(Math.atan2(y, x) / 1.57079633 + 5) % 4;
  //     }

  //     class Item {
  //         constructor(element) {
  //             this.element = element;
  //             this.element.addEventListener('mouseenter', (ev) => {
  //                 console.log('in');
  //                 this.element.classList.add('moving');

  //                 this.update(ev, 'in')
  //             });
  //             this.element.addEventListener('mouseout', (ev) => this.update(ev, 'out'));
  //         }

  //         update(ev, prefix) {
  //             this.element.classList.remove(...classNames);
  //             this.element.classList.add(`${prefix}-${directions[getDirectionKey(ev, this.element)]}`);
  //             setTimeout(() => {
  //                 this.element.classList.remove('moving');
  //             }, 50);
  //         }
  //     }

  //     nodes.forEach(node => new Item(node));
  // }

  const directions = { 0: 'top', 1: 'right', 2: 'bottom', 3: 'left' };
  const classNames = ['in', 'out']
    .map((p) => Object.values(directions).map((d) => `${p}-${d}`))
    .reduce((a, b) => a.concat(b));

  const getDirectionKey = (ev, node) => {
    const { width, height, top, left } = node.getBoundingClientRect();
    const l = ev.pageX - (left + window.pageXOffset);
    const t = ev.pageY - (top + window.pageYOffset);
    const x = l - (width / 2) * (width > height ? height / width : 1);
    const y = t - (height / 2) * (height > width ? width / height : 1);
    return Math.round(Math.atan2(y, x) / 1.57079633 + 5) % 4;
  };

  $('body').on('mouseenter', '.product-loop-item .featured-image', (ev) => {
    element = ev.currentTarget;
    element.classList.add('moving');
    updateHoverItem(element, ev, 'in');
  });

  $('body').on('mouseout', '.product-loop-item .featured-image', (ev) => {
    element = ev.currentTarget;
    updateHoverItem(element, ev, 'out');
  });

  function updateHoverItem(element, ev, prefix) {
    element.classList.remove(...classNames);
    element.classList.add(
      `${prefix}-${directions[getDirectionKey(ev, element)]}`
    );
    setTimeout(() => {
      element.classList.remove('moving');
    }, 100);
  }
});
