import { localForageDB, checkAndLoadProducts } from './localForageDB';
import $ from 'jquery';

$(() => {
  if (!$('#tool-tyres-by-size').length) {
    return;
  }

  localForageDB();

  var LIST_OF_ALL_PRODUCTS = null;
  var selectFields = [];
  var displayProducts = null;
  const $shopSelectResetFilter = $('.woo-shop-reset-select-filter');
  const $shopSelectFilters = $(
    '#tool-tyres-by-size .woo-shop-fitler-item .input'
  );

  function updateUI(products) {
    LIST_OF_ALL_PRODUCTS = fixHtmlEnties(products);
    displayProducts = LIST_OF_ALL_PRODUCTS;

    mapSelectFields();

    handleInputsToDisplayChange();
  }

  function handleInputsToDisplayChange() {
    // Check selects
    var selectsFiltering = {};

    $shopSelectFilters.each(function () {
      var $this = $(this);
      var field = $this.data('label');
      var value = $this.val();

      if (value) {
        selectsFiltering[field] = [value];

        if (value == 'R') {
          selectsFiltering[field] = [value, '80'];
        }
      }
    });

    displayProducts = filterInputs(LIST_OF_ALL_PRODUCTS, selectsFiltering, {});

    handleSelectOptionsUpdate(selectsFiltering);
  }

  function handleSelectOptionsUpdate(selectsFiltering) {
    var options = [];

    // Organized them
    selectFields.forEach(function (field) {
      var values = displayProducts.flatMap((product) => {
        if (Array.isArray(product[field])) {
          return [...product[field]];
        } else {
          return [product[field]];
        }
      });

      values = values.filter(
        (value, index, self) =>
          self.indexOf(value) === index &&
          value !== undefined &&
          value !== false
      );

      if (values.every((value) => !isNaN(value))) {
        values = values.sort((a, b) => a - b);
      } else {
        values = values.sort();
      }

      if (values && values.length > 0) {
        options[field] = values;
      }
    });

    if (
      (options['profile'] &&
        options['profile'].includes('80') &&
        options['profile'].length > 2) ||
      (options['profile'] &&
        options['profile'].includes('80') &&
        selectsFiltering['profile'] &&
        selectsFiltering['profile'].includes('R'))
    ) {
      options['profile'].unshift('R');
    }

    // Populate select options
    for (var field in options) {
      var values = options[field];

      var $select = $(`select[data-label="${field}"]`);

      // delete all options except the first one
      $select.find('option').not(':first').remove();

      if ($select.length) {
        values.forEach(function (value) {
          var $option = $('<option>', {
            value: value,
            text: value,
          });

          $select.append($option);
        });
      }
    }

    // Re select the options
    for (var field in selectsFiltering) {
      var value = selectsFiltering[field];

      $(`select[data-label="${field}"]`).val(value);
    }

    // Fixing R on Profile
    if (selectsFiltering['profile'] && selectsFiltering['profile'] == 80) {
      // check if has the option R
      if (options['profile'].includes('R')) {
        $(`select[data-label="profile"]`).val('R');
      }
    }
  }

  function filterInputs(products, filters, countField) {
    const filteredProducts = products.filter((product) => {
      // Assume product passes the filters until proven otherwise
      let passesFilters = Object.entries(filters).every(
        ([field, filterValues]) => {
          if (Array.isArray(product[field])) {
            return product[field].some((productValue) =>
              filterValues.includes(productValue)
            );
          } else {
            return filterValues.includes(product[field]);
          }
        }
      );

      return passesFilters; // Return the result of the filter check
    });

    return filteredProducts;
  }

  function init() {
    checkAndLoadProducts('tyre', function (products) {
      updateUI(products);
    });
  }

  function changeSelectFilter(e) {
    const $this = $(this);

    const $resetFilter = $this
      .siblings('.woo-shop-label-filter')
      .find('.woo-shop-reset-select-filter');

    $resetFilter.fadeIn(100);

    handleInputsToDisplayChange();
  }

  function resetSelectFilter(e) {
    e.preventDefault();

    const $this = $(this);

    const $select = $this.parent().siblings('select');

    $select.val('');

    $this.fadeOut(100);

    handleInputsToDisplayChange();
  }

  function submitFindTyres(e) {
    e.preventDefault();

    let selectedWidth = $('#woo-shop-fitler-width').val();
    let selectedProfile = $('#woo-shop-fitler-profile').val();
    let selectedRim = $('#woo-shop-fitler-rim').val();

    var url = '/shop/';

    selectedWidth = selectedWidth?.replaceAll('.', '-');
    selectedProfile = selectedProfile?.replaceAll('.', '-');
    selectedRim = selectedRim?.replaceAll('.', '-');

    if (selectedWidth && selectedProfile && selectedRim) {
      url += `size/${selectedWidth}-${selectedProfile}r${selectedRim}-tyres/`;
    } else if (selectedWidth) {
      url += `width/${selectedWidth}-wide-tyres/`;
    } else if (selectedProfile) {
      url += `profile/${selectedProfile}-aspect-ratio-tyres/`;
    } else if (selectedRim) {
      url += `rim/${selectedRim}-inch-tyres/`;
    } else {
      alert('Please select your tyre size');
      return;
    }

    window.location.href = url;
  }

  // Action
  $shopSelectFilters.on('change', changeSelectFilter);
  $shopSelectResetFilter.on('click', resetSelectFilter);
  $('#tyre-size-submit').on('click', submitFindTyres);

  //
  init();

  // Helpers
  function fixHtmlEnties(products) {
    return products.map((product) => {
      const fixedProduct = {};

      for (const key in product) {
        if (key == 'badges') {
          fixedProduct[key] = product[key];
          continue;
        } else if (Array.isArray(product[key])) {
          fixedProduct[key] = product[key].map((item) => {
            if (item) {
              return decodeHtmlEntities(item);
            }

            return item;
          });
        } else if (product[key]) {
          fixedProduct[key] = decodeHtmlEntities(product[key]);
        }
      }

      return fixedProduct;
    });
  }

  function decodeHtmlEntities(str) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = str;
    return textArea.value;
  }

  function mapSelectFields() {
    $shopSelectFilters.each(function () {
      var $this = $(this);
      var field = $this.attr('data-label');

      selectFields.push(field);
    });
  }
});
