// Slick Slider
import $ from 'jquery';
import 'slick-carousel/slick/slick.min';

$(() => {
  //Common Slide
  $('.slideshow-loop').each(function (num, elem) {
    elem = $(elem);

    const navigation = $(elem).parent().find('.slideshow-navigation');

    let options = {
      dots: false,
      draggable: true,
      adaptiveHeight: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      pauseOnHover: true,
      autoplay: true,
      autoplaySpeed: elem.attr('data-speed') * 1000,
      appendArrows: navigation,
      prevArrow: navigation.find('.slideshow-nav-left'),
      nextArrow: navigation.find('.slideshow-nav-right'),
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            arrows: false,
          },
        },
      ],
    };

    if (elem.attr('data-animation') == 'fade') {
      options.fade = true;
    }

    elem.slick(options);
  });

  //Common Slide
  $('.cta_services-loop').each(function (num, elem) {
    elem = $(elem);

    const navigation = $(elem).parent().find('.slideshow-navigation');

    let options = {
      dots: false,
      draggable: true,
      adaptiveHeight: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 5,
      arrows: true,
      pauseOnHover: true,
      autoplay: true,
      autoplaySpeed: 5000,
      appendArrows: navigation,
      prevArrow: navigation.find('.slideshow-nav-left'),
      nextArrow: navigation.find('.slideshow-nav-right'),
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            arrows: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            prevArrow: false,
            nextArrow: false,
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 770,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
          },
        },
      ],
    };

    if (elem.attr('data-animation') == 'fade') {
      options.fade = true;
    }

    elem.slick(options);
  });
});
