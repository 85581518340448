import { updateTotalPrice } from './single-product';
import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
    const tabs = document.querySelectorAll('.model-sizes-tabs-item');

    if (
        !(
            $('.single-tyre_model').length ||
            $('.single-battery_model').length ||
            $('.single-oil_model').length
        )
    )
        return;

    // Switch Price
    const productSelect = document.querySelector('#product-select');

    const allPrices = document.querySelectorAll('.product-prices-loop-item');

    if (!allPrices) return;
    if (!productSelect) return;

    const checkStock = () => {
        let productSelect = document.querySelector('#product-select');
        let dataStock =
            productSelect.options[productSelect.selectedIndex].dataset.stock;
        let stockDisplay = document.querySelector('#product-stock-display');

        if (dataStock > 0) {
            let qty = document.querySelector(
                'input[type="hidden"].input-text.qty'
            );

            if (qty) {
                qty.setAttribute('max', dataStock);

                if (qty.value > dataStock) {
                    qty.value = dataStock;
                }

                let stockQtyElement = stockDisplay.querySelector(
                    '#product-stock-display-qty'
                );

                stockQtyElement.textContent =
                    dataStock == 1 ? `${dataStock} tyre` : `${dataStock} tyres`;
                stockDisplay.style.display = 'block';
            }
        } else {
            stockDisplay.style.display = 'none';
        }
    };

    productSelect.addEventListener('change', function (e) {
        let selectedProduct = this.value;

        allPrices.forEach((price) => {
            price.classList.remove('block');
            price.classList.add('hidden');
        });

        let price = document.querySelector('#price-' + selectedProduct);

        if (price) {
            price.classList.remove('hidden');
            price.classList.add('block');
        }

        document.getElementById('product-plain-price').value =
            price.dataset.price;

        document.getElementById('data-add-to-cart').value = price.dataset.price;

        updateTotalPrice();

        checkStock();
    });

    checkStock();
});
