import $ from 'jquery';

$(() => {
  $('.tyre-size-top-picks-tabs-item').on('click', (e) => {
    setCurrentTab(e);
  });

  //Set Tab
  function setCurrentTab(e) {
    const element = $(e.currentTarget);
    const id = element.attr('data-id');
    // const loading = $('.tyre-size-top-picks-container .loading-comp');

    $('.tyre-size-top-picks-tabs-item').removeClass('current');

    element.addClass('current');

    // loading.fadeIn('fast');

    $('.tyre-size-top-picks-contents-item').css('display', 'none');
    $(`#tp-${id}`).fadeIn('fast');

    // setTimeout(() => {
    //     loading.fadeOut('fast');
    // }, 600);
  }
});
