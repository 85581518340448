import $ from 'jquery';

$(() => {
  $('.add-to-cart-using-ajax').on('click', (e) => {
    setProductToCart(e);
  });

  function setProductToCart(e) {
    const target = $(e.currentTarget);
    const product_id = target.attr('data-id');

    if (product_id) {
      let quantity = target.attr('data-qty');

      quantity = quantity ? quantity : 1;

      rudrAddToCart(product_id, quantity);

      $('.addons-feature-to-spin').block({
        message: null,
        overlayCSS: {
          background: '#fff',
          opacity: 0.6,
        },
      });

      $(`#addons-feature-item-${product_id}`).addClass('success');
    }
  }

  if (window.location.href.includes('?added-i')) {
    window.history.pushState({}, document.title, window.location.pathname);
  }
});

export const rudrAddToCart = (product_id, quantity = 1) => {
  return new Promise((resolve, reject) => {
    // let's check is add-to-cart.min.js is enqueued and parameters are presented
    if ('undefined' === typeof wc_add_to_cart_params) {
      reject('wc_add_to_cart_params is undefined');
    }

    jQuery.post(
      wc_add_to_cart_params.wc_ajax_url
        .toString()
        .replace('%%endpoint%%', 'add_to_cart'),
      {
        product_id: product_id,
        quantity: quantity, // quantity is hardcoced her
      },
      function (response) {
        // window.location.href = `${document.URL}?added-i=${product_id}`;
        // if (!response) {
        //     return;
        // }
        // // redirect is optional and it depends on what is set in WooCommerce configuration
        // if (response.error && response.product_url) {
        //     window.location = response.product_url;
        //     return;
        // }
        // if ('yes' === wc_add_to_cart_params.cart_redirect_after_add) {
        //     window.location = wc_add_to_cart_params.cart_url;
        //     return;
        // }
        // refresh cart fragments etc
        // jQuery(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash]);

        resolve(response);
      }
    );
  });
};
