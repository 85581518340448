import $ from 'jquery';

$(() => {
    const $block = $('#next-best-product-drawer');

    if (!$block.length) {
        return;
    }

    const $btnCloseDrawer = $('.next-best-product-trigger-close');
    const $btnOpenDrawer = $('.next-best-product-trigger-open');
    const $container = $('#next-best-product-container');
    const $header = $('#next-best-product-drawer-header');
    const $body = $('#next-best-product-drawer-body');
    const $btnFullComparison = $(
        '.btn-next-best-product-drawer-body-full-comparison'
    );

    $('#next-best-product-drawer-body--content').html(
        $('#next-best-product-drawer-body-content').clone()
    );

    $btnOpenDrawer.on('click', () => {
        $header.addClass('hidden');
        $body.css('display', 'block');
        $btnOpenDrawer.addClass('hidden');

        $container.css({
            width: 500,
        });
        $block.addClass('openned');

        setTimeout(() => {
            $body.addClass('show');
        }, 300);
    });

    function closeDrawer() {
        $body.css('display', 'none');
        $container.css({
            width: 400,
        });
        $body.removeClass('show');
        $block.removeClass('openned');

        $header.removeClass('hidden');
        $btnOpenDrawer.removeClass('hidden');
    }

    $btnCloseDrawer.on('click', closeDrawer);

    const observerCallback = (entries, observer) => {
        entries.forEach((entry) => {
            if ($block.hasClass('disabled')) {
                return;
            }

            if (entry.isIntersecting && $block.hasClass('bottom-0')) {
                if ($block.hasClass('openned')) {
                    $block.css({
                        bottom: -$block.height() - 60,
                    });

                    $block.addClass('disabled');

                    setTimeout(() => {
                        $block.remove();

                        observer.unobserve(entry.target);
                    }, 500);
                } else {
                    $block.addClass('-bottom-60').removeClass('bottom-0');
                }
            } else if (!entry.isIntersecting && !$block.hasClass('bottom-0')) {
                $block.removeClass('-bottom-60').addClass('bottom-0');
            }
        });
    };

    // Define the options for the observer
    const observerOptions = {
        root: null, // Use the viewport as the root
        rootMargin: '0px', // No margin around the root
        threshold: 0, // Trigger callback when 10% of the element is visible
    };

    const observer = new IntersectionObserver(
        observerCallback,
        observerOptions
    );

    $btnFullComparison.on('click', () => {
        $('html, body').animate(
            {
                scrollTop: $('#block-next-best-product').offset().top - 120,
            },
            300
        );
    });

    $(document).on('click', (e) => {
        // Check if the click is outside the drawer and not on the open button
        if (
            $block.hasClass('openned') &&
            !$block.is(e.target) && // Click is not directly on the drawer
            $block.has(e.target).length === 0 && // Click is not on a descendant of the drawer
            !$btnOpenDrawer.is(e.target) // Click is not on the open drawer button
        ) {
            closeDrawer();
        }
    });

    // init
    setTimeout(() => {
        observer.observe(document.querySelector('#block-next-best-product'));
        // $block.removeClass('-bottom-60').addClass('bottom-0');
    }, 1000);
});
