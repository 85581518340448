import number_format from './number-format';
import { updateTotalPrice } from './single-product';
import $ from 'jquery';

// Quantity
$(() => {
  $('body').on('click', ' .quantity .minus', function (e) {
    var $inputQty = $(this).parent().find('input.qty');
    var val = parseInt($inputQty.val());
    var step = $inputQty.attr('step');
    step = 'undefined' !== typeof step ? parseInt(step) : 1;
    if (val > 1) {
      $inputQty.val(val - step).change();
    }
  });
  $('body').on('click', ' .quantity .plus', function (e) {
    var $inputQty = $(this).parent().find('input.qty');
    var val = parseInt($inputQty.val());
    var step = $inputQty.attr('step');
    step = 'undefined' !== typeof step ? parseInt(step) : 1;

    var max = $inputQty.attr('max');
    max = 'undefined' !== typeof max ? parseInt(max) : false;

    if (max && val >= max) {
      return;
    }

    $inputQty.val(val + step).change();
  });

  // Update The Fitting Charge
  const $total = $('#product-tyre-fitting-charge-total');
  const $qtyFC = $('#product-tyre-fitting-charge-qty');
  $('.single-product div.product div.summary .quantity .qty').on(
    'change',
    () => {
      updateTotalPrice();
      // const $qty = parseFloat($('.single-product div.product div.summary .quantity .qty').val());

      // var price = parseFloat($total.attr('data-fitting-price')) * $qty;

      // $qtyFC.html(`x${$qty}`);
      // $('.show-fitting-charge').html('$' + number_format(price, 2, '.', ','));

      // price = (parseFloat($total.attr('data-product-price')) * $qty) + price;

      // $total.find('.show-total').html('$' + number_format(price, 2, '.', ','));
    }
  );

  //Toggle Fiting Chage
  $('#checkbox-fitting-charge').on('change', (e) => {
    const element = $(e.target);

    const $qty = parseFloat(
      $('.single-product div.product div.summary .quantity .qty').val()
    );
    var price = parseFloat($total.attr('data-product-price')) * $qty;

    if (element.is(':checked')) {
      price += parseFloat($total.attr('data-fitting-price')) * $qty;
    }

    $total.find('.show-total').html('$' + number_format(price, 2, '.', ','));
  });

  // Autocomplete - Checkout Page
  // const componentForm = {
  //     street_number: 'long_name',
  //     route: 'long_name',
  //     locality: 'long_name',
  //     sublocality_level_1: 'long_name',
  //     administrative_area_level_2: 'long_name',
  //     administrative_area_level_1: 'short_name',
  //     country: 'long_name',
  //     subpremise: 'long_name',
  //     postal_code: 'short_name'
  // };

  // if (document.getElementById("billing_address_1")) {

  //     var autocomplete = new google.maps.places.Autocomplete(
  //         document.getElementById("billing_address_1"), {
  //         componentRestrictions: {
  //             country: "nz"
  //         }
  //     });

  //     autocomplete.addListener("place_changed", () => {

  //         const place = autocomplete.getPlace();

  //         const addressOutput = {
  //             subpremise: '',
  //             street_number: '',
  //             route: '',
  //             locality: '',
  //             sublocality_level_1: '',
  //             administrative_area_level_1: '',
  //             country: '',
  //             postal_code: ''
  //         };

  //         if (!place.geometry || !place.geometry.location) {
  //             // User entered the name of a Place that was not suggested and
  //             // pressed the Enter key, or the Place Details request failed.
  //             window.alert("Please select one address!");
  //             return;
  //         }
  //         if (place.address_components) {

  //             Object.keys(addressOutput).forEach((element) => {

  //                 for (var i = 0; i < place.address_components.length; i++) {
  //                     var addressType = place.address_components[i].types;
  //                     if (addressType.includes(element)) {
  //                         var val = place.address_components[i][componentForm[element]];
  //                         addressOutput[element] = val;
  //                     }
  //                 }
  //             });

  //             var address = '', city = '', state = '', postcode = '';

  //             if (addressOutput['subpremise'] != '') {
  //                 address += addressOutput['subpremise'] + '/';
  //             }
  //             if (addressOutput['street_number'] != '') {
  //                 address += addressOutput['street_number'] + ' ';
  //             }
  //             if (addressOutput['route'] != '') {
  //                 address += addressOutput['route'];
  //             }
  //             if (addressOutput['sublocality_level_1'] != '') {
  //                 address += ', ' + addressOutput['sublocality_level_1'];
  //             }
  //             if (addressOutput['locality'] != '') {
  //                 city = addressOutput['locality'];
  //             }
  //             if (addressOutput['administrative_area_level_1'] != '') {
  //                 state = addressOutput['administrative_area_level_1'];
  //             }
  //             if (addressOutput['postal_code'] != '') {
  //                 postcode = addressOutput['postal_code'];
  //             }

  //             if (address) { $('#billing_address_1').val(address); }
  //             if (city) { $('#billing_city').val(city); }
  //             if (state) { $('#billing_state option:contains("' + state + '")').val(); }
  //             if (postcode) { $('#billing_postcode').val(postcode); }

  //             var stateValue = $('#billing_state option:contains("' + state + '")').val();
  //             $('#billing_state').val(stateValue).trigger('change');
  //         }
  //     });
  // }

  if ($.fn.select2) {
    $('.select2-custom').select2();
  }
});

//Disable Keyboard “Enter” on Checkout Page
$('form.checkout').on('keypress', function (e) {
  //Enter key
  if (e.which == 13) {
    return false;
  }
});
