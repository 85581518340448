
(function ($) {
    "use strict";

    $.fn.tabControl = function () {
        return this.each(function () {
            const $this = $(this);
            const tabHeaders = $this.find('.tab-control-index');
            const tabContents = $this.find('.tab-control-content');


            //Events
            tabHeaders.on('click', activeTabContent);

            //Active The tab pos click
            function activeTabContent(e) {
                const target = $(e.target);
                const index = target.attr('data-index');

                tabHeaders.removeClass('current');
                target.addClass('current');

                tabContents.addClass('hidden').removeClass('grid');
                $(`#tab-control-content-${index}`).removeClass('hidden').addClass('grid');
            }

        });
    }

    $('.tab-control').tabControl();

})(jQuery);