(function ($) {
    "use strict";

    $.fn.smoothScroll = function () {
        return this.each(function () {
            var $this = $(this);
            $this.on("click", (e) => {
                const target = $(e.target);
                let href = target.attr("href");

                if (href?.indexOf("#") == -1 || href.indexOf("#") > 2) {
                    return;
                }

                e.preventDefault();

                $("html, body").animate(
                    {
                        scrollTop: $(href).offset().top - 100,
                    },
                    500
                );
            });
        });
    };
})(jQuery);
